import React, { useEffect, useContext, useState } from "react"
import { navigate } from "gatsby-link"
import { AuthContext } from "../context/general"
import  { Amplify, Auth } from 'aws-amplify'
import { getTransaction, getEvent, getCycle } from '../graphql/queries'
import { updateTransaction } from '../graphql/mutations'
import { API, graphqlOperation } from "aws-amplify"
import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'
import { fetchTransactions } from '../helpers/fetchers'
import { Analytics } from 'aws-amplify'
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button'
import { useQueryParam, NumberParam, StringParam } from "use-query-params"


import awsConfig from '../aws-exports'
import { Typography } from "@mui/material"
Amplify.configure(awsConfig)

const Ics = () => {

  const { authState, authActions } = useContext(AuthContext)
  const [ready, setReady] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [eventData, setEventData] = useState(null)
  const [handled, setHandled] = useState(false)
  const [fetched, setFetched] = useState(false)

  const [foo, setFoo] = useQueryParam("response_wkToken", StringParam)

  useEffect(()=>{

    if (foo){

      console.log('@FOO')
      console.log(foo)

    }
  

  },[foo])



  useEffect(() => {

    
 


    const cognitoId = authState.user.cognitoId


   
    if ( !fetched && isAuthenticated && cognitoId) {

    
      
      fetchTransactions(cognitoId, authActions.setTransactions, authActions.setCards, authActions.set_HasNeverBought, authActions.appendTransactions, authActions.setCredits, authActions.setReplays, authActions.appendReplays)
      setFetched(true)
    
    }



  }, [isAuthenticated, authState])

  useEffect(() => {

    const paramsString = window.location.search
    var searchParams = new URLSearchParams(paramsString)

    console.log('@DEBUG')
    console.log(searchParams)
    console.log(URLSearchParams)

    const txId = searchParams.getAll("response_wkToken")[0]
    console.log(txId)
    const attributes = {
      transactionId : txId
    }

    const record = {
      name: 'txSuccesPageVisit',
      attributes: attributes }
   

    if (process.env.NODE_ENV === "production") {

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq('track', 'purchaseSuccess');
        }
      }

      Analytics.record(record)

    }



  }, [])


  const handleTX = (txId) => {

    console.log('@HANDLE TX')
    console.log(txId)





    API.graphql(graphqlOperation(getTransaction, { id: txId }))
      .then(({ data }) => {



        const scheduledTask = data.getTransaction.scheduledTask
        // CASE 1: HANDLE SINGLE
        // BASE CASE. CLIENT HAS GONE TRHOUGHT THE PROCESS AT REASONABLE PACE AND scheduledTask === SENDMAIL
        // OTHER WISE INITIAL MAIL HAS BEEN SENT BY ANOTHER PROCESS AND NOTHING TO DO
        const eventId = data.getTransaction.eventId
        if ((eventId != 'undetermined'))      // not === undetermined
        {

          console.log('@HANDLE EVENT')
          // SEND CONFIRMATION MAIL
          API.graphql(graphqlOperation(getEvent, { id: eventId }))
            .then(({data}) => {

              const getEventData = data.getEvent
              setEventData(getEventData)




            

              if ((scheduledTask === 'SENDMAIL')) {

                //https://lxk20o6rz0.execute-api.eu-west-1.amazonaws.com/dev

                const eventDate = getEventData.date
                fetch(`https://lxk20o6rz0.execute-api.eu-west-1.amazonaws.com/dev/${txId}`)
                  .then(_ => {

                    // PREPARE NEXT EVENT IN THE SCHEDULER
                    const now = new Date() / 1000
                    let scheduledTask = 'SENDMAIL1D'
                    let scheduledDate = eventDate - 3600 * 24

                    if ((eventDate - now) < 3600 * 24) {

                      scheduledTask = 'SENDMAIL1H'
                      scheduledDate = eventDate - 3600

                    }

                    if ((eventDate - now) < 3600) {

                      scheduledTask = 'QUESTIONNAIRE'
                      scheduledDate = eventDate + 3600

                    }

                   

                    API.graphql(graphqlOperation(updateTransaction, { input: {id: txId, scheduledTask: scheduledTask, scheduledDate: scheduledDate} }))
                      .then(data => console.log('NEW SCHEDULED OK'))
                      .catch(err => console.log(err))


                  })
                  .catch(err => console.log(err))

              }

              //PREVIOUS
              //setEventData(getEventData)
            })
            .catch(err => console.log(err))

        }


        // CASE 2: HANDLE CYCLE
        const cycleId = data.getTransaction.cycleId

        if ((cycleId != 'undetermined')){
          API.graphql(graphqlOperation(getCycle, { id: cycleId }))
          .then(({data})=>{
            setEventData(data.getCycle)
           
          })
          .catch(err=>console.log(err))
          
        }
       
        if ((cycleId != 'undetermined') && (scheduledTask === 'SENDMAIL')) {


          fetch(`https://lxk20o6rz0.execute-api.eu-west-1.amazonaws.com/dev/${txId}`)
            .then(_ => {

              const scheduledTask = 'CALCULATE'
              const scheduledDate = 0
              // PREPARE NEXT EVENT IN THE SCHEDULER
              API.graphql(graphqlOperation(updateTransaction, {input : { id: txId, scheduledTask: scheduledTask, scheduledDate: scheduledDate }}))
                .then(data => console.log('NEW SCHEDULED OK'))
                .catch(err => console.log(err))

            })
            .catch(err => console.log(err))


        }
      })
      .catch(err => console.log(err))


  }


  const onLoad = async () => {
    try {
      Auth.currentSession()
        .then(data => console.log(data))
        .catch(err => console.log(err))
      console.log('@AWAITED')
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        console.log('GROSS MOSTAKE')
        console.log(e)
      }
    }

    setIsAuthenticating(false);
  }

  useEffect(() => {

    console.log('@BRANCH-2')

  

    const paramsString = window.location.search
    var searchParams = new URLSearchParams(paramsString)
    const txId = searchParams.getAll("response_wkToken")[0]

    if (!isAuthenticating && !isAuthenticated) {
      authActions.toggleLoginModal(3)
    }

    if (!handled && !isAuthenticating && isAuthenticated) {

      handleTX(txId)
      setHandled(true)
    }



  }, [isAuthenticating, isAuthenticated])

  useEffect(() => {
    console.log('@BRANCH-3')
    const paramsString = window.location.search
    var searchParams = new URLSearchParams(paramsString)
    const txId = searchParams.getAll("response_wkToken")[0]


    if (!handled && !isAuthenticating && authState.user?.id){
      handleTX(txId)
      setHandled(true)
    }
     



  }, [authState])


  useEffect(() => {

    onLoad()

  }, [])


  useEffect(() => {

    console.log('@EVENT DATA')
    console.log(eventData)


    setTimeout(() => {
      navigate('/')
    }, 20000);

  }, [eventData])

  return (<Box width="100%" height="100%" marginTop="40vh" display="flex" flexDirection="row" justifyContent="center" justifyItems="center" alignContent="center" alignItems="center">
    {eventData && (eventData.title1 || eventData.title) && < Box borderRadius="20px" width="100%" maxWidth="450px" bgcolor={grey[200]} padding="30px">
      <Typography>
        Merci pour votre inscription {eventData.title1 && "à l'événement"}{eventData.title && "au cycle"}: <span style={{ fontWeight: 600 }}>{eventData && eventData.title1}{eventData && eventData.title} </span>. Vous allez recevoir un mail de confirmation dans votre boite mail très prochainement.
      </Typography>
      <Box padding="50px 0px 10px 0px" display="flex" flexDirection="row" justifyItems="center" justifyContent="center">
      <RetourButton onClick={()=>navigate('/')}>
        Retour à Pekoia
      </RetourButton>

      </Box>
      

    </Box>}

  </Box>)
}

const RetourButton = withStyles({
  root: {
    backgroundColor: "#343434",
    width: '120px',
    color: '#CDCDCD',
    height: '40px',
    borderRadius: '30px',
    fontSize: '0.6rem',

    '&:hover': {
      backgroundColor: grey[700]
    }
  },
  label: {
    textTransform: 'none',
  },
})(Button)





export default Ics